<template>
    <div>
        <el-button type="primary" class="download" @click="visible=!visible" >切换</el-button>
        <div v-if="visible">
            <!-- <el-row :gutter="0"> -->
                <div class="box-card-tip">
                    <div>
                        <i class="iconfont iconicon-13" ></i>
                        <span>您本次上传了N张发票，其中有M张图片文件需要您再次确认解析内容</span>
                        <span>{{visible}}</span>
                    </div>
                    <el-button type="primary" class="download" @click="batchDownLoad" >下载</el-button>
     
                </div>
            <!-- 左边发票 -->
            <div class="box-card-left ">
                <div class="compNav">
                    <div class="compNavIcon"></div>
                    <span>发票确认:N/N</span>
                </div>
                <div class="control">
                    <el-button type="primary" class="download" @click="batchDownLoad" >删除本张发票</el-button>
                    <div>
                        <!-- 翻页按钮 -->
                        <el-button class="el-icon-arrow-left f24" @click="forward"></el-button>
                        <el-button class="el-icon-arrow-right f24" @click="backwards"></el-button>
                    </div>
                </div>
                <div class="invoiceImgWrap">
                        <div class="invoiceImg" @click="RemInputEntrust($event,'true')" ref="b_00" data-index="b_00">
                            <!-- 基础部分 -->
                            <div class="b_01" data-index="b_01"  >
                                <div class="inputBox" v-if='true' ref="b_01" @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入票头"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}" v-model="invoiceInfoList[0].invHeader" class="input" id="input"  style="min-width: 40%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_02" data-index="b_02" >
                                <div class="inputBox" v-if='true' ref="b_02"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入发票代码"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].invCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_03" data-index="b_03" >
                                <div class="inputBox" v-if='true' ref="b_03"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入发票号码"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].invNumber" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_04" data-index="b_04" >
                                <div class="inputBox" v-if='true' ref="b_04"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入开票日期"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].doInvDate" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_05" data-index="b_05" >
                                <div class="inputBox" v-if='true' ref="b_05"  @click.stop >
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入校验码"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].checkCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_06" data-index="b_06" >
                                <div class="inputBox" v-if='true' ref="b_06"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入机器编码"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <!-- 购买方信息 -->
                            <div class="b_11" data-index="b_11">
                                <div class="inputBox" v-if='true' ref="b_11"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入名称"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_12" data-index="b_12">
                                <div class="inputBox" v-if='true' ref="b_12"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入纳税人识别码"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_13" data-index="b_13">
                                <div class="inputBox" v-if='true' ref="b_13"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入地址和电话"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_14" data-index="b_14">
                                <div class="inputBox" v-if='true' ref="b_14"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入开户行及账号"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_15" data-index="b_15">
                                <div class="inputBox" v-if='true' ref="b_15"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入密码区"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <!-- 销售方 -->
                            <div class="b_21" data-index="b_21">
                                <div class="inputBox" v-if='true' ref="b_21"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入名称"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_22" data-index="b_22">
                                <div class="inputBox" v-if='true' ref="b_22"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入纳税人识别码"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_23" data-index="b_23">
                                <div class="inputBox" v-if='true' ref="b_23"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入地址和电话"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_24" data-index="b_24">
                                <div class="inputBox" v-if='true' ref="b_24"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入开户行及账号"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_25" data-index="b_25">
                                <div class="inputBox" v-if='true' ref="b_25"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入备注"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_26" data-index="b_26">
                                <div class="inputBox" v-if='true' ref="b_26"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入收款人"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_27" data-index="b_27">
                                <div class="inputBox" v-if='true' ref="b_27"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入复核"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <div class="b_28" data-index="b_28">
                                <div class="inputBox" v-if='true' ref="b_28"  @click.stop>
                                    <div class="line"></div>
                                    <div class="dot"></div>
                                    <el-input placeholder="请输入开票人"  type="textarea" :autosize="{ minRows: 1, maxRows: 5}"  v-model="invoiceInfoList[0].machineCode" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                                </div>
                            </div>
                            <img src="../../../assets/invoice/invoice-Template.png" class="invoice-Template" style="width:100%" alt="">
                        </div>
                        <!-- <div class="inputBox" :style="{left: tranLeft, top: tranTop}" v-if='showBox'>
                            <div class="line"></div>
                            <div class="dot"></div>
                            <el-input placeholder="请输入查询条件"   v-model="ab" class="input" id="input"  style="width: 100%" @keyup.enter.native="handleQuery" @blur="RemInputHandle" ref="input"/>
                        </div> -->
                </div>
            </div>
            <!-- 右边信息表 -->
            <div class="box-card-right "> 
                <div class="compNav">
                    <div class="compNavIcon"></div>
                    <span>发票确认</span>
                </div>
                <div style="width:100%;">
                    <el-form :inline="true" :model="invoiceInfoList[0]" :rules="invoiceInfoRules" ref="invoiceInfoList" class="form-inline tz" style="width: 100%" label-width="30%">
                        <!-- 基本信息 -->
                        <el-row style="width: 100%"> 
                            <el-col :span="12">
                                <!-- 需交验 -->
                                <el-form-item label="票头" prop="invHeader" style="width: 98%" class="double_query" >
                                    <el-input placeholder="请输入票头" v-model="invoiceInfoList[0].invHeader" maxlength="25" class="filter-item" style="width: 100%"  @focus="focusHandle('b_01')" @blur="blurHandle('b_01')"  />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <!-- 需交验 -->
                                <el-form-item label="发票代码" prop="invCode" style="width: 98%" class="double_query">
                                    <el-input placeholder="请输入发票代码" v-model="invoiceInfoList[0].invCode" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_02')" @blur="blurHandle('b_02')"/>
                                </el-form-item>
                                </el-col>
                        </el-row>
                        <el-row style="width: 100%">
                            <el-col :span="12">
                                <!-- 需交验 -->
                                <el-form-item label="发票号码" prop="invNumber" style="width: 98%" class="double_query" >
                                    <el-input placeholder="请输入发票号码" v-model="invoiceInfoList[0].invNumber" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_03')" @blur="blurHandle('b_03')"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <!-- 需交验 -->
                                <el-form-item label="开票日期" prop="doInvDate" style="width: 98%" class="double_query">
                                    <el-input placeholder="请输入开票日期" v-model="invoiceInfoList[0].doInvDate" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_04')" @blur="blurHandle('b_04')"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="width: 100%">
                            <el-col :span="12">
                                <el-form-item label="校验码" prop="checkCode" style="width: 98%" class="double_query" >
                                    <el-input placeholder="请输入校验码" v-model="invoiceInfoList[0].checkCode" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_05')" @blur="blurHandle('b_05')"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="机器编码" prop="machineCode" style="width: 98%" class="double_query">
                                    <el-input placeholder="请输入机器编码" v-model="invoiceInfoList[0].machineCode" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_06')" @blur="blurHandle('b_06')"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 购买方信息 -->
                        <div class="compNavT"><strong>购买方信息：</strong></div>
                        <!-- 需交验 -->
                        <el-form-item label="名称" prop="buyerName" style="width: 100%" label-width="15%" class="single_query">
                            <el-input placeholder="请输入名称" v-model="invoiceInfoList[0].buyerName" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_11')" @blur="blurHandle('b_11')"/>
                        </el-form-item>
                        <el-form-item label="纳税人识别码" prop="buyerTaxpayerNum" style="width: 100%" label-width="15%" class="single_query">
                            <el-input placeholder="请输入纳税人识别码" v-model="invoiceInfoList[0].buyerTaxpayerNum" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_12')" @blur="blurHandle('b_12')"/>
                        </el-form-item>
                        <el-form-item label="地址/电话" prop="buyerPhoneNum" style="width: 100%" label-width="15%" class="single_query">
                            <el-input placeholder="请输入地址和电话" v-model="invoiceInfoList[0].buyerPhoneNum" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_13')" @blur="blurHandle('b_13')"/>
                        </el-form-item>
                        <el-form-item label="开户行及账号" prop="buyerBankCardNum" style="width: 100%" label-width="15%" class="single_query">
                            <el-input placeholder="请输入开户行及账号" v-model="invoiceInfoList[0].buyerBankCardNum" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_14')" @blur="blurHandle('b_14')"/>
                        </el-form-item>
                        <!-- 需交验 -->
                        <el-form-item label="密码区" prop="buyerPassword" style="width: 100%" label-width="15%" class="single_query">
                            <el-input placeholder="请输入密码区" v-model="invoiceInfoList[0].buyerPassword" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_15')" @blur="blurHandle('b_15')"/>
                        </el-form-item>
                        <!-- 销售方信息 -->
                        <div class="compNavT"><strong>销售方信息</strong></div>
                        <!-- 需校验 -->
                        <el-form-item label="名称" prop="sellName" style="width: 100%" label-width="15%" class="single_query">
                            <el-input placeholder="请输入名称" v-model="invoiceInfoList[0].sellName" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_21')" @blur="blurHandle('b_21')"/>
                        </el-form-item>
                        <!-- 需校验 -->
                        <el-form-item label="纳税人识别码" prop="sellTaxpayerNum" style="width: 100%" label-width="15%" class="single_query">
                            <el-input placeholder="请输入纳税人识别码" v-model="invoiceInfoList[0].sellTaxpayerNum" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_22')" @blur="blurHandle('b_22')"/>
                        </el-form-item>
                        <el-form-item label="地址/电话" prop="sellPhoneNum" style="width: 100%" label-width="15%" class="single_query">
                            <el-input placeholder="请输入地址和电话" v-model="invoiceInfoList[0].sellPhoneNum" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_23')" @blur="blurHandle('b_23')"/>
                        </el-form-item>
                        <el-form-item label="开户行及账号" prop="sellBankCardNum" style="width: 100%" label-width="15%" class="single_query">
                            <el-input placeholder="请输入开户行及账号" v-model="invoiceInfoList[0].sellBankCardNum" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_24')" @blur="blurHandle('b_24')"/>
                        </el-form-item>
                        <el-form-item label="备注" prop="sellPassword" style="width: 100%" label-width="15%" class="single_query">
                            <el-input placeholder="请输入备注" v-model="invoiceInfoList[0].sellPassword" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_25')" @blur="blurHandle('b_25')"/>
                        </el-form-item>
                        <el-row style="width: 100%">
                            <el-col :span="12">
                                <el-form-item label="收款人" prop="payee" style="width: 98%" class="double_query" >
                                    <el-input placeholder="请输入收款人" v-model="invoiceInfoList[0].payee" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_26')" @blur="blurHandle('b_26')"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="复核" prop="review" style="width: 98%" class="double_query">
                                    <el-input placeholder="请输入复核" v-model="invoiceInfoList[0].review" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_27')" @blur="blurHandle('b_27')"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="width: 100%">
                            <el-col :span="12">
                                <el-form-item label="开票人" prop="drawer" style="width: 98%" class="double_query" >
                                    <el-input placeholder="请输入开票人" v-model="invoiceInfoList[0].drawer" maxlength="25" class="filter-item" style="width: 100%" @focus="focusHandle('b_28')" @blur="blurHandle('b_28')"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- 项目表格 -->
                        <div class="projectFormWrap" id="projectFormWrap" >
                            <div class="projectForm">
                                <el-row class="projectFormTitle bt">
                                    <el-col :span="6">货物或应税劳务、服务名称</el-col>
                                    <el-col :span="4">规格型号</el-col>
                                    <el-col :span="2">单位</el-col>
                                    <el-col :span="2">数量</el-col>
                                    <el-col :span="2">单价</el-col>
                                    <el-col :span="3">金额</el-col>
                                    <el-col :span="2">税率</el-col>
                                    <el-col :span="3">税额</el-col>
                                </el-row>
                                <!-- <el-row class="projectFormTitle" v-for="(item, index) in projectFormCom" :key="index"> -->
                                <el-row class="projectFormTitle" v-for="(item, index) in invoiceInfoList[0].projectForm" :key="index">
                                    <i class="iconfont iconicon-11" style="color:#35C4B1" @click="addProjectHandle"></i>
                                    <!-- 需校验 -->
                                    <el-col :span="6" class="colItem"><el-form-item :prop="'projectForm.'+index+'.goodsName'" :rules="invoiceInfoRules.goodsName" ><el-input placeholder="货物或应税劳务、服务名称" v-model="item.goodsName"  style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                                    <el-col :span="4"><el-input placeholder="规格型号" v-model="item.speModel"  style="width: 90%" @keyup.enter.native="handleQuery"/></el-col>
                                    <el-col :span="2"><el-input placeholder="单位" v-model="item.units"  style="width: 90%" @keyup.enter.native="handleQuery"/></el-col>
                                    <!-- 需交验 -->
                                    <el-col :span="2" class="colItem"><el-form-item :prop="'projectForm.'+index+'.number'" :rules="invoiceInfoRules.number" ><el-input placeholder="数量" v-model="item.number"  style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                                    <!-- 需交验 -->
                                    <el-col :span="2" class="colItem"><el-form-item :prop="'projectForm.'+index+'.unitPrice'" :rules="invoiceInfoRules.unitPrice" ><el-input placeholder="单价" v-model="item.unitPrice"  style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                                    <!-- 需交验 -->
                                    <el-col :span="3" class="colItem"><el-form-item :prop="'projectForm.'+index+'.money'" :rules="invoiceInfoRules.money" ><el-input placeholder="金额" v-model="item.money"  style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                                    <!-- 需交验 -->
                                    <el-col :span="2" class="colItem"><el-form-item :prop="'projectForm.'+index+'.taxRate'" :rules="invoiceInfoRules.taxRate" ><el-input placeholder="税率" v-model="item.taxRate"  style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                                    <!-- 需交验 -->
                                    <el-col :span="3" class="colItem"><el-form-item :prop="'projectForm.'+index+'.taxMoney'" :rules="invoiceInfoRules.taxMoney" ><el-input placeholder="税额" v-model="item.taxMoney"  style="width: 90%" @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                                    <i class="iconfont iconicon-12" style="color:#FF0000" @click="delProjectRow(index)"></i>
                                </el-row>
                                <el-row class="projectFormTitle">
                                    <el-col :span="2">合计：</el-col>
                                    <el-col :span="14"></el-col>
                                    <el-col :span="3"><el-input placeholder="合计金额" v-model="invoiceInfoList[0].totalMoney"  style="width: 90%" @keyup.enter.native="handleQuery"/></el-col>
                                    <el-col :span="2"></el-col>
                                    <el-col :span="3"><el-input placeholder="合计税额" v-model="invoiceInfoList[0].totalTaxMoney"  style="width: 90%" @keyup.enter.native="handleQuery"/></el-col>
                                </el-row>
                                <el-row class="projectFormTitle">
                                    <el-col :span="6">价税合计(大写):{{invoiceInfoList[0].totalPriceTaxDX}}</el-col>
                                    <el-col :span="10"></el-col>
                                    <el-col :span="8"  class="itemContent colItem"><el-form-item prop="totalPriceTax" label="小写:" label-width="20%" style="width: 98%"><el-input placeholder="价税合计" v-model="invoiceInfoList[0].totalPriceTax"  style="width: 100%%"  @keyup.enter.native="handleQuery"/></el-form-item></el-col>
                
                                </el-row>
                            </div>
                        </div>
                        <el-button type="primary" @click="submitForm('invoiceInfoList')"></el-button>
                    </el-form>
                </div>
            </div>

        </div>
        <upload-list v-else></upload-list>
    </div>
</template>

<script>
import uploadList from './components/index'
export default {
    name: 'WebIndex',
    components:{ uploadList },
    data() {
    /* 票头校验 */
    const checkInvHeader = (rule, value, callback) => {
        if(value) {
          const Reg = /(普通发票|专用发票)$/;
          if(!Reg.test(value)){
            return callback( new Error("必须包含“普通发票”或“专用发票”字样") )
          } 
        } else {
            return callback( new Error("请输入票头信息") )
          }
        callback()
      }
    /* 发票代码校验 */
    const checkInvCode = (rule, value, callback) => {
        if(value) {
          const Reg = /^\d{12}$/;
          if(!Reg.test(value)){
            return callback( new Error("发票代码为12位纯数字") )
          } 
        } else {
            return callback( new Error("请输入发票代码") )
          }
        callback()
      }
    /* 发票号码校验 */
    const checkInvNumber = (rule, value, callback) => {
        if(value) {
          const Reg = /^\d{8}$/;
          if(!Reg.test(value)){
            return callback( new Error("发票代码为8位纯数字") )
          } 
        } else {
            return callback( new Error("请输入发票号码") )
          }
        callback()
      }
    /* 开票日期校验 */
    const checkDoInvDate = (rule, value, callback) => {
        if(value) {
        //   const invDoInvDate = /^((([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})年(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29))$/;
          const invDoInvDate = /^(\d{4})(年)(\d{2})(月)(\d{2})(日)$/;
          if(!invDoInvDate.test(value)){
            return callback( new Error("日期格式为XXXX年XX月XX日") )
          } 
        } else {
            return callback( new Error("请输入发票号码") )
          }
        callback()
      }
    /* 数量校验 */
    const checkNumber = (rule, value, callback) => {
        // console.log(rule, value,'121212');
        if(value) {
          const Reg = /^((([^0][0-9]+|0)\.([0-9]{1,2}))$)|^(([1-9]+)(\.([0-9]{1,5}))?$)/;
          if(!Reg.test(value)){
            return callback( new Error("数量最大支持5位小数") )
          } 
        } 
        callback()
      }
    /* 单价校验 */
    const checkNnitPrice = (rule, value, callback) => {
        if(value) {
          const Reg = /^((([^0][0-9]+|0)\.([0-9]{1,2}))$)|^(([1-9]+)(\.([0-9]{1,10}))?$)/;
          if(!Reg.test(value)){
            return callback( new Error("单价最大支持10位小数") )
          } 
        } 
        callback()
      }
    /* 金额校验 */
    const checkMoney = (rule, value, callback) => {
        if(value) {
          const Reg = /^((([^0][0-9]+|0)\.([0-9]{1,2}))$)|^(([1-9]+)(\.([0-9]{1,5}))?$)/;
          if(!Reg.test(value)){
            return callback( new Error("金额最大支持2位小数") )
          } 
        } else {
            return callback( new Error("请输入金额") )
          }
        callback()
      }
    /* 税率校验 */
    const checkTaxRate = (rule, value, callback) => {
        if(value) {
          const Reg = /(%)$/
        //   value = parseInt(value)
          if(value!='免税'&&!Reg.test(value)){
            return callback( new Error("请输入含'%'的数字或'免税'字样") )
          }
        //   if(!Reg.test(value)){
        //     return callback( new Error("数量最大支持5位小数") )
        //   } 
        } else {
            return callback( new Error("请输入税率") )
          }
        callback()
      }
    /* 税额校验 */
    const checkTaxMoney = (rule, value, callback) => {
        if(value) {
          const Reg = /^((([^0][0-9]+|0)\.([0-9]{1,2}))$)|^(([1-9]+)(\.([0-9]{1,2}))?$)/;
          if(value!='***'&&!Reg.test(value)){
            return callback( new Error("税额最大支持2位小数") )
          } 
        } else {
            return callback( new Error("请输入税额") )
          }
        callback()
      }
    /* 加税合计校验 */
    const checkTotalPriceTax = (rule, value, callback) => {
        if(value) {
          const Reg = /^((([^0][0-9]+|0)\.([0-9]{1,2}))$)|^(([1-9]+)(\.([0-9]{1,2}))?$)/;
          if(value!='***'&&!Reg.test(value)){
            return callback( new Error("价税合计最大支持2位小数") )
          } 
        } else {
            return callback( new Error("请输入税额") )
          }
        callback()
      }
        return {
            queryForm: {
            },
            invoiceInfoList: [{
            // 基本信息
            invHeader: null,
            invCode: null,
            invNumber: null,
            doInvDate: null,
            checkCode: null,
            machineCode: null,
            //购买方信息
            buyerName: null,
            buyerTaxpayerNum: null,
            buyerPhoneNum: null,
            buyerAddress: null,
            buyerBankCardNum: null,
            buyerPassword: null,
            //销售方信息
            sellName: null,
            sellTaxpayerNum: null,
            sellPhoneNum: null,
            sellAddress: null,
            sellBankCardNum: null,
            sellPassword: null,
            payee: null,  //收款人
            review: null, // 复查
            drawer: null, //开票人
            // 项目表格
            projectForm: [{
                goodsName:null,
                speModel:null,
                units:null,
                number:null,
                unitPrice:null,
                money:null,
                taxRate:null,
                taxMoney:null,
            }],
            totalMoney:null,
            totalTaxMoney:null,
            totalPriceTaxDX:null,
            totalPriceTax:null,



            }],
            invoiceInfoRules:{
                //静态表单校验
                invHeader:[ {required: true,validator: checkInvHeader, trigger: 'blur'}],
                invCode:[ { required: true, validator: checkInvCode, trigger: 'blur' }],
                invNumber:[ { required: true, validator: checkInvNumber, trigger: 'blur' }],
                doInvDate:[ { required: true, validator: checkDoInvDate, trigger: 'blur' }],
                buyerName:[ { required: true, message: '请输入购买方名称', trigger: 'blur' }],
                buyerPassword:[ { required: true, message: '请输入密码区内容', trigger: 'blur' }],
                sellName:[ { required: true, message: '请输入销售方名称', trigger: 'blur' }],
                sellTaxpayerNum:[ { required: true, message: '请输入销售方纳税人识别码', trigger: 'blur' }],
                totalPriceTax:[ {required: true,validator: checkTotalPriceTax, trigger: 'blur'}],

                /* 动态表单校验 */
                goodsName:[ {  required: true, message: '请输入项目名称', trigger: 'blur' }],
                number:[ {  validator: checkNumber, trigger: 'blur' }],
                unitPrice:[ { validator: checkNnitPrice, trigger: 'blur' }],
                money:[ { required: true, validator: checkMoney, trigger: 'blur' }],
                taxRate:[ { required: true, validator: checkTaxRate, trigger: 'blur' }],
                taxMoney:[ { required: true, validator: checkTaxMoney, trigger: 'blur' }],
                
            },
            domObj:null,
            /*  */
            ab:null,
            domObj:null,
            showBox:false,
            tranLeft:'400px',
            tranTop:'400px',
            idList:['b_01','b_02','b_03','b_04','b_05','b_06','b_11','b_12','b_13','b_14','b_15','b_21','b_22','b_23','b_24','b_25','b_26','b_27','b_28',],
            visible:''
        };
    },
    created(){
            // this.visible = this.$route.query.visible

    },

    mounted() {
        // this.scrollFunction()
        // setTimeout(function () {
        //     this.scrollFunction()
        //   }, 100)
        //输入框自动聚焦
        // this.$nextTick(() => {
            this.$route.query.visible=='true' ? this.visible = true : this.visible =false
            
            // console.log(this.$route.query.visible,'111111111111111121',typeof( this.visible));
    //   });
    },

    methods: {
        //向前翻页
        forward(){

        },
        //向后翻页
        backwards(){},
        RemInputHandle(){
            // this.showBox = false
        },
        //输入框聚焦时
        focusHandle(id){
            // console.log(this.$refs[id].style.display);
            this.idList.forEach(item =>this.$refs[item].style.display= 'none')
            this.$refs[id].style.display= 'block'
            console.log(id,'输入框id');
            // console.log(this.$refs[id]);
        },
        //输入框失去焦点时
        blurHandle(id){
            console.log('失去焦点');

        },
        // 点击区域事件委托
        RemInputEntrust(e,state){
            console.log('输入框冒泡了');
            if(e.target.dataset.index=='b_00') return false
            this.idList.forEach(item =>this.$refs[item].style.display= 'none')
            let id = e.target.dataset.index
            console.log(id,'idd')

            this.$refs[id].style.display= 'block'

            // console.log(e.target.dataset.index)
            // if(state=='true'){
                // console.log(e,'点击事件')
                // this.tranLeft = (e.pageX - 500) + 'px'
				// this.tranTop = (e.pageY - 163) + 'px'
                // var sTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                // this.tranLeft = (e.pageX - 312 /1920 * window.innerWidth) + 'px'
				// this.tranTop = (e.pageY - 360 /1920 * window.innerWidth)+ sTop + 'px'
                // this.domObj = document.getElementById('input')
                // this.domObj.style.top = event.clientX
                // this.domObj.style.left = event.clientY
                // console.log(this.domObj.style.top,'1',event.clientY)
                // console.log(this.$refs.input,'ref',this.domObj.style)
                // if(e.target.dataset.index){
                //     this.showBox = true
                //     this.$nextTick(() => {
                //         this.$refs.input.focus();
                //     });
                // } else {
                //     this.showBox = false
                // }

                
            // }
        },
        batchDownLoad(){},
        scrollFunction () {
            this.domObj = document.getElementById('projectFormWrap') // 通过id获取要设置的div
            if (this.domObj.attachEvent) { // IE
                this.domObj.attachEvent('onmousewheel', this.mouseScroll)
            } else if (this.domObj.addEventListener) {
                this.domObj.addEventListener('DOMMouseScroll', this.mouseScroll, false)
            }
            this.domObj.onmousewheel = this.domObj.onmousewheel = this.mouseScroll
        },
        mouseScroll(event) { // google 浏览器下
            let detail = event.wheelDelta || event.detail
            let moveForwardStep = -1
            let moveBackStep = 1
            let step = 0
            step = detail > 0 ? moveForwardStep * 100 : moveBackStep * 100
            event.preventDefault() // 阻止浏览器默认事件
            this.domObj.scrollLeft = this.domObj.scrollLeft + step
        } ,
        // 金额小写转大写
        cnMoneyFormat(money) {
            var cnMoney = "零元整";
            var strOutput = "";
            var strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
            money += "00";
            var intPos = money.indexOf('.');
            if (intPos >= 0) {
                money = money.substring(0, intPos) + money.substr(intPos + 1, 2);
            }
            strUnit = strUnit.substr(strUnit.length - money.length);
            for (var i = 0; i < money.length; i++) {
                strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(money.substr(i, 1), 1) + strUnit.substr(i, 1);
            }
            cnMoney = strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元");
            return cnMoney;
        },
        // 添加商品信息行
        addProjectHandle(){
            let objProjectRow ={
                goodsName:null,
                speModel:null,
                units:null,
                number:null,
                unitPrice:null,
                money:null,
                taxRate:null,
                taxMoney:null,
            }
            this.invoiceInfoList[0].projectForm.unshift(objProjectRow)
        },
        // 删除行
        delProjectRow(index){
            if(this.invoiceInfoList[0].projectForm.length<=1) return
            this.invoiceInfoList[0].projectForm.splice(index,1)
        },
        /* 提交按钮 */
        submitForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(valid);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    }
    },
    computed:{
        
    },
    beforeDestroy(){
        if (!this.domObj) return
        if (this.domObj.attachEvent) {
        this.domObj.detachEvent('onmousewheel', this.mouseScroll)
        }
        if (this.domObj.addEventListener) {
        this.domObj.removeEventListener('DOMMouseScroll', this.mouseScroll, false)
        }

    },

};
</script>

<style lang="less" scoped>
.inputBox{
    // position: relative;
    display: none;
    position: absolute;
    bottom: 48%;
    left: 20%;
    // transform: translate(50%,-50%);
    width: 200px;
    height: 35px;

    .line {
        position: absolute;
        bottom: 0;
        left: 3px;
        width: 28px;
        height: 42px;
        border-top: 2px solid #FF9700;
        border-left: 2px solid #FF9700;
        border-radius: 4px;
    }
    .dot {
        position: absolute;
        bottom: 0;
        left: 0px;
        width: 8px;
        height: 8px;
        background-color: #FF9700;
        border-radius: 4px;
    }
    /deep/ .el-textarea__inner {
        position: absolute;
        bottom: 8px;
        left: 22px;
        // border: 0;
        border: 1px solid #FF9700;
        border-radius: 8px;
        resize:none;
    }
    .input {
        z-index: 999;
    }
}

// #input.el-input__inner {
//     border: 1px solid rgb(235, 0, 0);
// }
.box-card-tip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1564px;
    height: 60px;
    padding: 22px;
    margin-bottom: 22px;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    background-color: #fff;
    .iconicon-13 {
        color: rgba(255, 151, 0);
        padding-right: 6px;
    }
    .download {
    width: 100px;
    height: 40px;
    padding: 0;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    // color: #ffffff;
  }
}
.box-card-left,.box-card-right{
    float: left;
    width: 770px;
    min-height: 1370px;
    background-color: #fff;
    margin-right: 24px;
    padding:20px 20px 80px;
    .compNav {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        padding-bottom: 16px;
        // border-bottom-width: 4px;
        // border-bottom-color: #444;
        border-bottom: 1px solid #d1d1d1;
        margin-bottom: 24px;
        font-size: 16px;
        .compNavIcon {
            width: 4px;
            height: 16px;
            margin-right: 12px;
            background-color: #409EFF;
            border-radius: 50em;
        }
    }


}

.el-col-12 {
    margin: 0;
}

/deep/.form-inline {
    .double_query {
        .el-form-item__content {
        width: 70% !important;
        margin-right:0 !important;
        }
    }
    .single_query {
        .el-form-item__content {
        width: 84% !important;
        margin-right:0 !important;
        }
    }
}
.compNavT {
    padding: 24px 0;
    // border-bottom-width: 4px;
    // border-bottom-color: #444;
    // border-bottom: 1px solid #d1d1d1;
    // margin-bottom: 24px;
    font-size: 16px;
}
.invoice-Template {
    position:sticky;
    top: 0;
    z-index: -9;
}
.projectFormWrap {
    width: 100%;
    // height: 100px;
    overflow: scroll;
}

.projectForm {
    width: 900px;
    font-size: 14px;
    // text-align: center;
    border: 1px solid #d1d1d1;
    padding: 12px;
}
.projectFormTitle {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #d1d1d1;

}
.control {
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.f24{
    font-size: 22px;
    padding: 7px;
    margin: 0 10px;
}

.colItem {
    /deep/.el-form-item{
    width: 100%;
    margin: 0;
    }

// .itemContent {
//     .el-form-item__content {
//         width: 50%;
//     }
// }
}

/* ——————发票区域标注—————— */
 .invoiceImgWrap {
    // position: relative;
    position: sticky;
    top: 60px;
    width: 100%;
}
.invoiceImg {
    position: sticky;
    top: 60px;
    width: 100%;
    
}
// .inputBox {
//     transition: all 10s;
// }
.b_01 {
    position: absolute;
    top: 16px;
    left: 224px;
    width: 286px;
    height: 48px;
    // border: 1px solid #000;
}
.b_02 {
    position: absolute;
    top: 10px;
    left: 516px;
    width: 179px;
    height: 19px;
    color: #121212;
    // border: 1px solid #000;

}
.b_03 {
    position: absolute;
    top: 31px;
    left: 516px;
    width: 179px;
    height: 19px;
    color: #121212;
    // border: 1px solid #000;
}
.b_04 {
    position: absolute;
    top: 52px;
    left: 516px;
    width: 179px;
    height: 19px;
    color: #121212;
    // border: 1px solid #000;
}
.b_05 {
    position: absolute;
    top: 73px;
    left: 516px;
    width: 181px;
    height: 19px;
    color: #121212;
    // border: 1px solid #000;
}
.b_06 {
    position: absolute;
    top: 69px;
    left: 30px;
    width: 134px;
    height: 26px;
    color: #121212;
    // border: 1px solid #000;
}
.b_11 {
    position: absolute;
    top: 100px;
    left: 52px;
    width: 360px;
    height: 17px;
    color: #121212;
    // border: 1px solid #000;
}
.b_12 {
    position: absolute;
    top: 118px;
    left: 52px;
    width: 360px;
    height: 17px;
    color: #121212;
    // border: 1px solid #000;
}
.b_13 {
    position: absolute;
    top: 136px;
    left: 52px;
    width: 360px;
    height: 17px;
    color: #121212;
    // border: 1px solid #000;
}
.b_14 {
    position: absolute;
    top: 154px;
    left: 52px;
    width: 360px;
    height: 17px;
    color: #121212;
    // border: 1px solid #000;
}
.b_15 {
    position: absolute;
    top: 101px;
    left: 433px;
    width: 275px;
    height: 69px;
    color: #121212;
    // border: 1px solid #000;
}
.b_16 {
    position: absolute;
    top: 10px;
    left: 100px;
    width: 100px;
    height: 40px;
    color: #121212;
    // border: 1px solid #000;
}
.b_21 {
    position: absolute;
    top: 350px;
    left: 55px;
    width: 360px;
    height: 17px;
    color: #121212;
    // border: 1px solid #000;
}
.b_22 {
    position: absolute;
    top: 367px;
    left: 55px;
    width: 360px;
    height: 17px;
    color: #121212;
    // border: 1px solid #000;
}
.b_23 {
    position: absolute;
    top: 384px;
    left: 55px;
    width: 360px;
    height: 17px;
    color: #121212;
    // border: 1px solid #000;
}
.b_24 {
    position: absolute;
    top: 401px;
    left: 55px;
    width: 360px;
    height: 17px;
    color: #121212;
    // border: 1px solid #000;
}
.b_25 {
    position: absolute;
    top: 350px;
    left: 436px;
    width: 274px;
    height: 66px;
    color: #121212;
    // border: 1px solid #000;
}
.b_26 {
    position: absolute;
    top: 420px;
    left: 36px;
    width: 120px;
    height: 24px;
    color: #121212;
    // border: 1px solid #000;
}
.b_27 {
    position: absolute;
    top: 420px;
    left: 226px;
    width: 120px;
    height: 24px;
    color: #121212;
    // border: 1px solid #000;
}
.b_28 {
    position: absolute;
    top: 420px;
    left: 370px;
    width: 120px;
    height: 24px;
    color: #121212;
    // border: 1px solid #000;
}
// .b_21 {
//     position: absolute;
//     top: 174px;
//     left: 27px;
//     width: 182px;
//     height: 126px;
//     color: #121212;
//     border: 1px solid #000;
// }
// .b_22 {
//     position: absolute;
//     top: 174px;
//     left: 212px;
//     width: 49px;
//     height: 126px;
//     color: #121212;
//     border: 1px solid #000;
// }
// .b_23 {
//     position: absolute;
//     top: 174px;
//     left: 263px;
//     width: 38px;
//     height: 126px;
//     color: #121212;
//     border: 1px solid #000;
// }
// .b_24 {
//     position: absolute;
//     top: 174px;
//     left: 306px;
//     width: 74px;
//     height: 126px;
//     color: #121212;
//     border: 1px solid #000;
// }
// .b_25 {
//     position: absolute;
//     top: 174px;
//     left: 388px;
//     width: 76px;
//     height: 126px;
//     color: #121212;
//     border: 1px solid #000;
// }
// .b_26 {
//     position: absolute;
//     top: 174px;
//     left: 471px;
//     width: 101px;
//     height: 126px;
//     color: #121212;
//     border: 1px solid #000;
// }
// .b_27 {
//     position: absolute;
//     top: 174px;
//     left: 576px;
//     width: 29px;
//     height: 126px;
//     color: #121212;
//     border: 1px solid #000;
// }
// .b_28 {
//     position: absolute;
//     top: 174px;
//     left: 606px;
//     width: 102px;
//     height: 126px;
//     color: #121212;
//     border: 1px solid #000;
// }


</style>

(
    (
        ([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})
        (年)
        (((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8])))
    )
        (月)
    (
        (([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29(日)
    )
)

